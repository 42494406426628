import React from "react"
import { graphql, PageProps } from "gatsby"
import { Drvspace7 } from "@react95/icons"
import { UiModal, AnchorLink, AnchorsGroupWrapper, UiLink } from "../components"
import { mapRemarkToPage, rcEncodeUri } from "../hooks"
import {
  ContactForm,
  Input,
  Fieldset,
  TextArea,
  ProgressBar,
  ContactWrapper,
} from "../styles/views/contact.style"
import { spacing } from "../styles/theme/variables"
import { Tab, Tabs } from "@react95/core"
import { Helmet } from "react-helmet"
import { Markdown, CommonPageProps } from "../@types"

export const ContactPageLayout: React.FC<CommonPageProps> =
  React.forwardRef(({ data, onClose, path, routeId }, ref) => {
    const pageData = mapRemarkToPage<{
      title: string
      description: string
      messagePlaceholder: string
      submitText: string
      afterSubmitText: string
      options: {
        title: string
        description: string
        list: { text: string; urls: string[] }[]
      }
    }>(data.markdownRemark)

    const [formError, setFormError] = React.useState(false)
    const [isSending, setIsSending] = React.useState(false)
    const [progress, setProgress] = React.useState(0)
    const [isSent, setIsSent] = React.useState(false)
    const [contact, setContact] = React.useState(null)
    const [intervalIndex, setIntervalIndex] = React.useState<any>(undefined)
    const disabled = isSending || isSent

    const handleChange = (e) => {
      setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e: any) => {
      e.preventDefault()
      if (disabled) return
      setIsSending(true)
      
      const interval = setInterval(() => {
        // Increment progress bar randomly between 0 to 30%
        setProgress((p) => p + Math.floor(Math.random() * 30))
      }, 200)
      setIntervalIndex(interval)

      const form = e.target
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: rcEncodeUri({
          "form-name": form.getAttribute("name"),
          ...contact,
        }),
      })
        .then(({ ok }) => !ok && setFormError(true))
        .finally(() => {
          if (isSending) setIsSending(false)
        })
    }

    React.useEffect(() => {
      if (progress >= 200) {
        setIsSending(false)
        setProgress(0)
        setIsSent(true)
        clearInterval(intervalIndex)
        setIntervalIndex(undefined)
      }
    }, [progress])

    return (
      <UiModal
        title="Contact"
        closeModal={onClose}
        defaultPosition={{ x: 200, y: 70 }}
        icon={Drvspace7}
        mobileOrder={2}
        size="medium"
        maxHeight="400"
        scrollable
        ref={ref}
        data-route={routeId}
        frameProps={{ boxShadow: "none" }}
      >
        <Tabs defaultActiveTab={pageData.title}>
          <Tab title={pageData.title}>
            <ContactWrapper>
              {pageData.title && <h1>{pageData.title}</h1>}
              {pageData.description && <p>{pageData.description}</p>}

              <ContactForm
                onSubmit={onSubmit}
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                {/* Netlify */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>

                <Fieldset legend="Personal Details" flex>
                  <Input
                    name="name"
                    placeholder="Name"
                    type="text"
                    autoComplete="name"
                    disabled={disabled}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    disabled={disabled}
                    onChange={handleChange}
                    required
                  />
                </Fieldset>

                <Fieldset legend="Message">
                  <TextArea
                    rows={10}
                    cols={50}
                    name="message"
                    placeholder={pageData.messagePlaceholder}
                    disabled={disabled}
                    onChange={handleChange}
                    required
                  />
                </Fieldset>

                <AnchorLink
                  type="submit"
                  style={{ minHeight: 44 }}
                  disabled={disabled}
                >
                  {isSending ? (
                    <ProgressBar percent={Math.min(progress, 100)} />
                  ) : (
                    <React.Fragment>
                      {isSent ? pageData.afterSubmitText : pageData.submitText}
                    </React.Fragment>
                  )}
                </AnchorLink>
              </ContactForm>
            </ContactWrapper>
          </Tab>

          <Tab title={pageData.options.title}>
            <ContactWrapper>
              {pageData.options.title && <h1>{pageData.options.title}</h1>}
              {pageData.options.description && (
                <p>{pageData.options.description}</p>
              )}

              {pageData.options.list && (
                <React.Fragment>
                  {pageData.options.list.map((item) => (
                    <Fieldset legend={item.text} key={item.text}>
                      <AnchorsGroupWrapper margin={spacing(0.45) + " 0"}>
                        {item.urls.map((url) => {
                          url = url.trim()
                          const isEmail = url.includes("@"),
                            href = isEmail ? `mailto:${url}` : url
                          return (
                            <AnchorLink
                              as={UiLink}
                              key={url}
                              to={href}
                              fontSize={0.8}
                            >
                              {url}
                            </AnchorLink>
                          )
                        })}
                      </AnchorsGroupWrapper>
                    </Fieldset>
                  ))}
                </React.Fragment>
              )}
            </ContactWrapper>
          </Tab>
        </Tabs>
      </UiModal>
    )
  })

const ContactPage: React.FC<PageProps<Markdown<any>>> = ({ data }) => {
  return (
    // @ts-ignore
    <Helmet>
      <title>{data.markdownRemark.frontmatter.title}</title>
      <meta
        property="og:title"
        content={`${data.markdownRemark.frontmatter.title} / Status Zero`}
      />
      {data.markdownRemark.excerpt && (
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.excerpt}
        />
      )}
      {data.markdownRemark.excerpt && (
        <meta property="og:description" content={data.markdownRemark.excerpt} />
      )}
    </Helmet>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        description
        messagePlaceholder
        submitText
        afterSubmitText
        options {
          title
          description
          list {
            text
            urls
          }
        }
      }
    }
  }
`
