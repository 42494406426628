import React from "react"
import { List, Frame } from "@react95/core"
import { graphql, PageProps } from "gatsby"
import { CdMusic, Mailnews12, Binoc, Bat } from "@react95/icons"
import { mapRemarkToPage } from "../hooks"
import {
  HTMLBody,
  HTMLContent,
  Image,
  UiModal,
  YoutubeOrImage,
  AnchorLink,
  AnchorsGroupWrapper,
  UiLink,
} from "../components"
import {
  PressWrapper,
  PortraitContainer,
  PressInfoGrid,
  FloatingHeaderFrame,
  YoutubeVideoContainer,
} from "../styles/views/press.style"
import { useStatusZero } from "../queries"
import { Divider } from "../styles/theme"
import { CommonPageProps, LinkType } from "../@types"
import { Helmet } from "react-helmet"

type MusicPageLayoutProps = {
  children?: React.ReactNode
  onClose?: () => void
  isPreviewMode?: boolean
}

export type Press = {
  title: string
  date: Date
  header: {
    mainImage: any
    youtubeUrl: string
  }
  button: LinkType
  embedHtml: string
  portrait: string
}

export type PressMarkdown = Partial<{
  markdownRemark: {
    frontmatter: Press
    id: string
    html: string
    excerpt: string
  }
}>

export const PressPageLayout: React.FC<CommonPageProps> = React.memo(
  React.forwardRef(({ onClose = () => {}, data, routeId }, ref) => {
    const statusZeroData = useStatusZero()
    const itemData: Press = mapRemarkToPage(data?.markdownRemark || data)

    return (
      <UiModal
        fullscreen
        scrollable
        closeModal={onClose}
        title={itemData.title}
        icon={CdMusic}
        ref={ref}
        data-route={routeId}
        buttons={[{ value: "Explore more", onClick: onClose }]}
        menu={[
          {
            name: "File",
            list: (
              <List style={{ zIndex: 999 }}>
                <List.Item onClick={onClose}>Exit</List.Item>
              </List>
            ),
          },
          {
            name: "Help",
            list: (
              <List style={{ zIndex: 999 }}>
                {statusZeroData?.contactEmail && (
                  <List.Item
                    onClick={onClose}
                    icon={<Mailnews12 variant="16x16_4" />}
                    // @ts-ignore
                    as={"a"}
                    href={"mailto:" + statusZeroData.contactEmail}
                    style={{ textDecoration: "none" }}
                  >
                    Contact me
                  </List.Item>
                )}

                <List.Item
                  icon={<Bat variant="16x16_4" />}
                  // @ts-ignore
                  as={UiLink}
                  to={itemData.button?.url}
                  style={{ textDecoration: "none" }}
                >
                  {itemData.button?.text}
                </List.Item>

                <List.Divider />

                <List.Item icon={<Binoc variant="16x16_4" />}>
                  Last updated: {itemData.date}
                </List.Item>
              </List>
            ),
          },
        ]}
      >
        <PressWrapper>
          <YoutubeVideoContainer>
            <YoutubeOrImage
              ytUrl={itemData.header?.youtubeUrl}
              image={itemData.header?.mainImage}
              title={itemData.title}
              style={{}}
            />
          </YoutubeVideoContainer>

          {/* <hr /> */}

          <PressInfoGrid>
            <FloatingHeaderFrame padding="10" bg="#656565">
              {data.markdownRemark?.html && (
                <HTMLContent content={data.markdownRemark?.html} />
              )}
            </FloatingHeaderFrame>

            <PortraitContainer padding="10">
              <Image src={itemData.portrait} alt={itemData.title} />
            </PortraitContainer>
          </PressInfoGrid>

          <Frame padding="10" bg="#656565">
            {itemData.embedHtml && <HTMLBody content={itemData.embedHtml} />}
          </Frame>

          <Divider />

          <AnchorLink as={UiLink} to={itemData.button?.url} fontSize={1.5}>
            {itemData.button?.text}
          </AnchorLink>

          <Divider />

          {statusZeroData?.socialMedia?.length > 0 && (
            <AnchorsGroupWrapper>
              {statusZeroData.socialMedia.map((social, index) => {
                return (
                  <AnchorLink as={UiLink} to={social?.url} key={index}>
                    {social.text}
                  </AnchorLink>
                )
              })}
            </AnchorsGroupWrapper>
          )}
        </PressWrapper>
      </UiModal>
    )
  })
)

const PressPage: React.FC<PageProps<PressMarkdown>> = ({ data }) => {
  return (
    // @ts-ignore
    <Helmet>
      <title>{data.markdownRemark.frontmatter.title}</title>
    </Helmet>
  )
}

export default PressPage

export const pageQuery = graphql`
  query PressPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 200)
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        embedHtml
        button {
          url
          text
        }
        header {
          youtubeUrl
          mainImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.7777777777777777
                placeholder: BLURRED
              )
            }
          }
        }
        portrait {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
