import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const useErrorPage = () => {
  const data = useStaticQuery(
    graphql`
      query ErrorPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "error-page" } }) {
          html
          excerpt(pruneLength: 200)
          frontmatter {
            title
            subtitle
            blueScreen {
              title
              description
            }
          }
        }
      }
    `
  )
  const { markdownRemark } = data
  return { ...markdownRemark, ...markdownRemark?.frontmatter } as {
    title: string
    subtitle: string
    blueScreen: {
      title: string
      description: string
    }
  }
}
