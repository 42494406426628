import React from "react"
import { PageProps } from "gatsby"
import { SmashGame } from "../components/SmashGame/SmashGame"
import { Helmet } from "react-helmet"

export const SmashGameLayout: React.FC<Partial<PageProps>> = (props) => {
  return <SmashGame {...(props as any)} />
}

export const SmashGamePage = () => {
  return (
    // @ts-ignore
    <Helmet>
      <title>Smash Game</title>
      <meta property="og:title" content={`Smash Game / Status Zero`} />
    </Helmet>
  )
}

export default SmashGamePage
