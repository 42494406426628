import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { mapRemarkToPage } from "../hooks"
import { Gallery, Music } from "../@types"

export const useFeaturedPost = () => {
  const data = useStaticQuery(
    graphql`
      query FeaturedPostsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { featuredpost: { eq: true } } }
          limit: 1
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                subtitle
                youtubeUrl
                date(fromNow: true)
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      aspectRatio: 1
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return {
    id: data.id,
    ...mapRemarkToPage<(Music | Gallery)[]>(data.allMarkdownRemark.edges)[0],
  }
}
