import styled from "styled-components"
import { mq } from "../../styles/theme"
import * as DesktopFolders from "../DesktopFolders/DesktopFolders"

export const LogoContainer = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  /* pointer-events: none; */
  /* user-select: none; */
  svg {
    min-height: min(100%, 300px);
    max-height: fit-content;
    height: 100%;
    width: 100%;
    pointer-events: all;

    max-width: 30vh;
    ${mq("<md")} {
      max-width: 50vw;
    }
  }
`

export const DesktopLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 1rem;
  z-index: 0;

  ${DesktopFolders.DesktopFoldersWrapper} {
    z-index: 1;
  }

  ${LogoContainer} {
    z-index: 0;
  }
`
