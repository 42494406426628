import React from "react"
import styled from "styled-components"
import { mq } from "../../styles/theme"
import { spacing } from "../../styles/theme/variables"

export const FeaturedPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  hr {
    width: 100%;
    flex: 1;
  }
`
export const FeaturedPostGrid = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-bottom: ${spacing(0.75)};
  margin: auto;

  ${mq("<md")} {
    flex-direction: column;
  }

  ${mq("<md")} {
    padding-bottom: ${spacing(2)};
  }
`

export const PostStart = styled.div`
  flex: 1 1 100%;
  max-width: 280px;
  .video-yt,
  .ui-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${mq("<md")} {
    max-width: 100%;
  }
`

export const PostEnd = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }

  ${mq(">md")} {
    margin-inline-start: ${spacing(0.75)};
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const NPImage = ({ ...p }) => <img loading="lazy" width="80" {...p} />

export const NewPost = styled(NPImage)`
  flex: 0;
  margin-block: auto 1rem;
  align-self: flex-end;
  /* margin: -${86 * 0.2}px 0; */
`

const ArrowImage = ({ ...p }) => <img loading="lazy" height={1 * 16} {...p} />
export const ButtonArrowGif = styled(ArrowImage)`
  transform: rotate(90deg);
  max-height: 1rem;
  margin-inline-start: 1ch;
  /* margin: -${86 * 0.2}px 0; */
`
