import { navigate } from "gatsby"
import React from "react"
import { isMobile } from "../../hooks"
import { StatusZeroLogoFilledMob } from "../svg"
import { SmashGUIScreen } from "./GUI/SmashGUIScreen"
import { SmashScoreBoard } from "./GUI/SmashScoreBoard"
import { GAME_CONFIG } from "./smash-game.config"
import {
  CloseGameButton,
  GameGUI,
  GameLayout,
  Mob,
  Rules,
} from "./SmashGame.style"

export type SmashGameElement = {
  id: number
  left?: number
  isDead?: boolean
  isMissed?: boolean
  missedClickOnce?: boolean
}

export const LOG = (msg) => console.log("[SmashGame]", msg)

export const SmashGame = ({ onClose: _onClose }) => {
  const [score, setScore] = React.useState(0)
  const [missedCount, setMissedCount] = React.useState(0)
  const [gameOver, setGameOver] = React.useState(false)
  const [gameStarted, setGameStarted] = React.useState(false)
  const [gameElements, setGameElements] = React.useState<SmashGameElement[]>([])
  const [gameInterval, setGameInterval] = React.useState(null)

  const resetGame = () => {
    setScore(0)
    setMissedCount(0)
    setGameStarted(false)
    setGameOver(false)
    gameInterval && clearInterval(gameInterval)
    setGameElements([] as SmashGameElement[])
  }

  const onGameFinish = (isGameOver: boolean = false) => {
    gameInterval && clearInterval(gameInterval)

    setGameStarted(false)

    if (isGameOver) setGameOver(true)
  }

  const updateMissedScore = () => {
    if (missedCount + 1 > 9) {
      onGameFinish(true)
    }
    setMissedCount(missedCount + 1)
  }

  const updateScore = () => {
    setScore(score + 1)
  }

  const killElement =
    (id: number, isMissed: boolean = false) =>
    () => {
      const byId = gameElements.find((e) => e.id === id)
      if (byId) {
        if (isMissed) {
          byId.isMissed = true
          updateMissedScore()
        } else {
          byId.isDead = true
          updateScore()
        }

        setGameElements([...gameElements])
      }
    }

  const addElement = () => {
    const size =
      GAME_CONFIG.mob.width *
      (isMobile ? GAME_CONFIG.mob.mobileSizeMultiplier : 1)
    setGameElements((prev) => [
      ...prev,
      {
        id: (prev?.length || 0) + 1,
        left: Math.floor(
          Math.random() *
            ((typeof window !== "undefined" ? window.innerWidth : size) - size)
        ),
        isDead: false,
        isMissed: false,
      },
    ])
  }

  const onGameStart = () => {
    resetGame() // reset game state ONLY before running
    setGameStarted(true)
    LOG("Game started")
    const int = setInterval(() => {
      addElement()
    }, 650)
    setGameInterval(int)
  }

  React.useEffect(() => {
    return () => {
      gameInterval && clearInterval(gameInterval)
    }
  }, [])

  const onClickClear = (id) => () => {
    const byId = gameElements.find((e) => e.id === id)
    if (
      score < 10 ||
      (Math.random() > 0.3 && !byId.missedClickOnce) ||
      byId.missedClickOnce
    ) {
      return killElement(id)()
    }
    byId.missedClickOnce = true
    setGameElements([...gameElements])
    return
  }

  const onClose = () => {
    gameInterval && clearInterval(gameInterval)
    navigate("/")
    _onClose?.()
  }

  return (
    <GameLayout>
      {!gameStarted ? (
        gameOver ? (
          <SmashGUIScreen
            title="GAME OVER"
            primaryButton={{ text: "Restart game", onClick: onGameStart }}
          >
            <SmashScoreBoard hits={score} miss={missedCount} margin="2rem 0" />
          </SmashGUIScreen>
        ) : (
          <SmashGUIScreen
            title="SMASH GAME"
            primaryButton={{ text: "Start game", onClick: onGameStart }}
          >
            <Rules>
              <h2>HOW TO PLAY</h2>
              <ul>
                <li>You have to hit the smiley as fast as you can.</li>
                <li>
                  You can miss clicks{" "}
                  <strong>up to {GAME_CONFIG.maxFailures} times</strong>
                </li>
                <li>
                  After a while the smiley gains his{" "}
                  <strong>30% chance of dodging</strong> your hit, so be fast
                  and accurate!
                </li>
              </ul>
            </Rules>
          </SmashGUIScreen>
        )
      ) : null}

      <GameGUI>
        {gameStarted && (
          <SmashScoreBoard absolute hits={score} miss={missedCount} />
        )}
        <CloseGameButton onClick={onClose}>X</CloseGameButton>
      </GameGUI>

      {gameStarted &&
        gameElements.map(
          (el, index) =>
            !el.isDead &&
            !el.isMissed && (
              <Mob
                key={el.id}
                onClick={onClickClear(el.id)}
                onAnimationEnd={killElement(el.id, true)}
                odd={index % 2 === 0}
                didMissedOnce={el.missedClickOnce}
                style={{ left: el.left }}
              >
                <StatusZeroLogoFilledMob width="100%" height="100%" />
              </Mob>
            )
        )}
    </GameLayout>
  )
}
