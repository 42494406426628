import React from "react"
import {
  DesktopFoldersWrapper,
  DesktopIconButton,
  IconWrapper,
} from "./DesktopFolders.style"
import { Folder } from "@react95/icons"
import { navigate } from "gatsby"
import Image from "../Image"
export * from "./DesktopFolders.style"

type Folder = {
  icon: React.ReactNode
  title: string
  url: string
  children?: Folder[]
}

export type DesktopFoldersProps = {
  folders: any[]
  white?: boolean
  offset?: boolean
  row?: boolean
  margin?: string
  imageIcon?: any
}

const DesktopIcon = ({
  name = "New Folder",
  url,
  state = {},
  title,
  icon: Icon = Folder as any,
  imageIcon,
  onClick = (): any => {
    throw new Error("onClick not implemented")
  },
  white,
}) => {
  return (
    <DesktopIconButton
      title={title || "Click to enter " + name}
      to={url}
      draggable
      state={state}
      white={white}
    >
      <IconWrapper tabIndex={-1}>
        {imageIcon ? (
          <Image src={imageIcon} alt={name} />
        ) : (
          <Icon variant="32x32_4" alt={name} />
        )}
        <span>{name}</span>
      </IconWrapper>
    </DesktopIconButton>
  )
}

export const DesktopFolders: React.FC<DesktopFoldersProps> = ({
  folders,
  white,
  row = false,
  margin = null,
}) => {
  const navigateOnClick = React.useCallback((url) => {
    return () => navigate(url)
  }, [])

  if (!folders?.length) return null
  return (
    <DesktopFoldersWrapper row={row} margin={margin}>
      {folders.map((folder) => (
        <DesktopIcon
          key={folder.url + folder.name}
          {...folder}
          white={white}
          onClick={navigateOnClick(folder.url)}
        />
      ))}
    </DesktopFoldersWrapper>
  )
}
