import {
  PressPageLayout,
  GalleryPageLayout,
  GalleryItemPageLayout,
  ErrorPageLayout,
  MusicPageLayout,
  MusicItemPageLayout,
  ContactPageLayout,
  AboutPageLayout,
  SmashGameLayout,
} from "../../templates/"
import {
  Folder,
  Shell3232,
  Drvspace7,
  Progman24,
  Gcdef100,
  Freecell1,
  Mmsys90,
} from "@react95/icons"

interface IRoute {
  path: string
  component: React.FC<any>
  exact?: boolean
}

export const $routes: IRoute[] = [
  {
    path: "/smashgame",
    component: SmashGameLayout,
    exact: true,
  },
  {
    path: "/press",
    component: PressPageLayout,
    exact: true,
  },
  {
    path: "/error",
    component: ErrorPageLayout,
    exact: true,
  },
  {
    path: "/contact",
    component: ContactPageLayout,
    exact: true,
  },
  {
    path: "/about",
    component: AboutPageLayout,
    exact: true,
  },
  {
    path: "/music/*",
    component: MusicPageLayout,
  },
  {
    path: "/music/.",
    component: MusicItemPageLayout,
  },
  {
    path: "/gallery/*",
    component: GalleryPageLayout,
  },
  {
    path: "/gallery/.",
    component: GalleryItemPageLayout,
  },
]

export const FoldersArray = [
  {
    icon: Mmsys90,
    name: "Music",
    url: "/music",
  },
  {
    icon: Freecell1,
    name: "Gallery",
    url: "/gallery",
  },
  {
    icon: Drvspace7,
    name: "Contact",
    url: "/contact",
  },
  {
    icon: Progman24,
    name: "About",
    url: "/about",
  },
  {
    icon: Folder,
    name: "Unreleased content",
    url: "/error",
  },
  {
    icon: Gcdef100,
    name: "Fancy a game?",
    url: "/smashgame",
  },
  {
    icon: Shell3232,
    name: "Recycle bin",
    url: "/",
    state: { clearDesktop: true },
    title: "Double click to clear windows",
  },
]

export const getPageComponentsFromPath = (pagePath: string) => {
  // remove trailing slash from path
  const pageComponents = $routes.filter(({ path, exact }) => {
    const regexString = exact ? `^${path}$` : `^${path}`
    return new RegExp(regexString).test(pagePath)
  })

  return pageComponents
}
