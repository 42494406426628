import React from "react"
import { Tab, Tabs } from "@react95/core"
import { graphql, PageProps } from "gatsby"
import { CdMusic } from "@react95/icons"
import {
  combineTitles,
  mapRemarkToPage,
  parseSpotifyToEmbed,
  generatePaginationButtons,
} from "../hooks"
import {
  AnchorsGroup,
  HTMLContent,
  Image,
  SpotifyWidget,
  UiModal,
  YoutubeVideo,
} from "../components"
import { CommonPageProps, Markdown, Music } from "../@types"
import { PageWrapper } from "../styles/theme"
import { Helmet } from "react-helmet"
import { getSrc } from "gatsby-plugin-image"

export const MusicItemPageLayout: React.FC<CommonPageProps<Music>> =
  React.forwardRef(({ onClose, data, routeId }, ref) => {
    const itemData: Music = mapRemarkToPage(data.markdownRemark)
    const paginationButtons = generatePaginationButtons(data)
    const fullName = React.useMemo(() => {
      return combineTitles(itemData.title, itemData.subtitle)
    }, [itemData.title, itemData.subtitle])
    return (
      <UiModal
        title={fullName || "Song"}
        closeModal={onClose}
        defaultPosition={{ x: 800, y: 50 }}
        icon={CdMusic}
        size="medium"
        mobileOrder={2}
        scrollable
        ref={ref}
        data-route={routeId}
        buttons={paginationButtons}
      >
        <PageWrapper>
          <Image src={itemData.featuredimage} alt={itemData.title} />

          <h1>{itemData.subtitle}</h1>
          <h2>{itemData.title}</h2>

          <hr />

          <HTMLContent content={data.markdownRemark.html} />

          {itemData?.spotifyUri || itemData?.youtubeUrl ? (
            <div className="dummy">
              <Tabs>
                <Tab title="Spotify">
                  {itemData?.spotifyUri ? (
                    <SpotifyWidget
                      url={parseSpotifyToEmbed(itemData.spotifyUri)}
                      title={fullName}
                      height={
                        itemData.spotifyUri.includes("playlist")
                          ? 300
                          : undefined
                      }
                    />
                  ) : (
                    <p>Not available</p>
                  )}
                </Tab>

                <Tab title="Video">
                  {itemData?.youtubeUrl ? (
                    <YoutubeVideo url={itemData.youtubeUrl} title={fullName} />
                  ) : (
                    <p>Not available</p>
                  )}
                </Tab>
              </Tabs>
            </div>
          ) : null}

          <AnchorsGroup list={itemData?.dsp} style={{ marginTop: "1rem" }} />
        </PageWrapper>
      </UiModal>
    )
  })

const MusicItemPage: React.FC<PageProps<Markdown>> = ({ data }) => {
  const itemData: Music = mapRemarkToPage(data.markdownRemark)
  const src = getSrc(data.markdownRemark.frontmatter.featuredimage)
  const fullName = combineTitles(itemData.title, itemData.subtitle)

  return (
    // @ts-ignore
    <Helmet>
      <title>{fullName}</title>
      <meta property="og:title" content={`Status Zero / ${fullName}`} />
      <meta property="og:image" content={src} />
      {data.markdownRemark.excerpt && (
        <meta name="description" content={data.markdownRemark.excerpt} />
      )}
      {data.markdownRemark.excerpt && (
        <meta property="og:description" content={data.markdownRemark.excerpt} />
      )}
    </Helmet>
  )
}

export default MusicItemPage

export const pageQuery = graphql`
  query MusicItemPageQuery(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 200)
      frontmatter {
        title
        subtitle
        date
        spotifyUri
        youtubeUrl
        dsp {
          text
          url
        }
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 1
              placeholder: BLURRED
            )
          }
        }
      }
    }
    prev: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
