export const GAME_CONFIG = {
  maxFailures: 10,

  mob: {
    width: 250,
    height: 250,
    mobileSizeMultiplier: 0.8,
    speedInSeconds: 1,
  },
}
