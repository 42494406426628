import React from "react"
import { navigate } from "gatsby"
import { DesktopFolders } from "../DesktopFolders/DesktopFolders"
import { StatusZeroLogo, StatusZeroLogoFilled } from "../svg"
import { FoldersArray } from "../WindowsManager/windows.routes"
import { DesktopLayout, LogoContainer } from "./Desktop.style"
import { navigateWithParams } from "../../hooks"

// prettier-ignore
const pointers = ["pointer", "text", "not-allowed", "wait", "help", "grab", "grabbing", "crosshair"]

type DesktopProps = {}
export const Desktop: React.FC<DesktopProps> = React.memo(() => {
  const [secretClickCount, setSecretClickCount] = React.useState(0)

  React.useEffect(() => {
    if (secretClickCount === 7) {
      setSecretClickCount(0)
      navigateWithParams("/error", {
        title: "TOO MANY CLICKS",
        message: "Clicked too much, and now you will get banned",
        skip: true,
      })
    }
  }, [secretClickCount])

  return (
    <DesktopLayout>
      <DesktopFolders folders={FoldersArray} white margin="-1rem" />
      <LogoContainer>
        <StatusZeroLogoFilled
          height="100%"
          width="100%"
          style={{ cursor: pointers[secretClickCount] }}
          onClick={() => setSecretClickCount(secretClickCount + 1)}
        />
      </LogoContainer>
    </DesktopLayout>
  )
})
