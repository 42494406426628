import React from "react"
import { Helmet } from "react-helmet"
import { getSrc } from "gatsby-plugin-image"
import { Variables } from "../../styles/theme/variables"
import { useStatusZero } from "../../queries"

const SEO: React.FC = () => {
  const { title, excerpt: description, keywords, ogImage } = useStatusZero()
  const ogImageSrc = getSrc(ogImage)

  return (
    // @ts-ignore
    <Helmet defaultTitle={title} titleTemplate={"%s / " + title}>
      <html lang="en" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />
      <meta name="theme-color" content={Variables.$bg} />

      <meta property="og:type" content="business.business" />
      <meta property="og:url" content="/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageSrc} />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:creator" content={config.site.author} /> */}
      <meta name="twitter:site" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default SEO
