import React from "react"
import { simulateMouseClick } from "../../hooks"
import { getPageComponentsFromPath } from "./windows.routes"

type WindowsWindow = {
  path: string
  component: any
  componentPath: string
  context: any
}
const newVisitedPage = (path, pageProps) => ({
  [path]: { pageProps, timestamp: new Date().getTime() },
})

const findClosedWindowsForPath = (path: string, closedWindows: any) => {
  const currentPathComponents = getPageComponentsFromPath(path)
  const newClosedWindows = Object.entries(closedWindows).reduce(
    (acc, [componentPath, isShown]) => {
      if (isShown !== false) return acc

      const needToBeShown = currentPathComponents.findIndex(
        ({ path }) => path === componentPath
      )

      return { ...acc, [componentPath]: needToBeShown > -1 }
    },
    {}
  )
  return newClosedWindows
}

export const WindowsManager = ({ path, ...pageProps }) => {
  const lastPath = React.useRef(path)
  const [closedWindows, setClosedWindows] = React.useState({})
  const [visitedPages, setVisitedPages] = React.useState(
    newVisitedPage(path, pageProps)
  )

  React.useLayoutEffect(() => {
    // Focus the last window that was navigated to
    const currPathWindows = getPageComponentsFromPath(path)
    const lastWin = currPathWindows?.[currPathWindows.length - 1]
    if (lastWin) focusAndScrollWindow(lastWin.path, lastPath.current === path)

    // If the path has changed, update the visited pages
    // else, quit the effect
    if (lastPath.current === path) return
    else lastPath.current = path

    // Resetting all windows to be hidden if clearDesktop is true
    if (pageProps.location?.state?.clearDesktop)
      setVisitedPages(newVisitedPage(path, pageProps))
    // If the path has changed, update the visited pages
    else
      setVisitedPages({ ...visitedPages, ...newVisitedPage(path, pageProps) })

    // Reopening windows that were closed
    const closedWindowsToOpen = findClosedWindowsForPath(path, closedWindows)

    // If there are no windows to open, quit the effect
    if (!Object.keys(closedWindowsToOpen).length) return

    // Open the windows
    setClosedWindows({ ...closedWindows, ...closedWindowsToOpen })
  }, [pageProps.location])

  const onRequestClose = React.useCallback(
    (closedComponent) => () => {
      setClosedWindows({ ...closedWindows, [closedComponent]: false })
    },
    [closedWindows]
  )

  const getWindows = React.useMemo(() => {
    const pagesWithComponent = Object.keys(visitedPages)
      .sort((a, b) => visitedPages[a].timestamp - visitedPages[b].timestamp)

      .reduce((acc, pagePath) => {
        const matchers = getPageComponentsFromPath(pagePath).map(
          ({ component, path: componentPath }) => {
            return [
              componentPath,
              {
                path: pagePath,
                component,
                componentPath,
                context: visitedPages[pagePath].pageProps,
              },
            ]
          }
        )

        return {
          ...acc,
          ...Object.fromEntries(matchers), // Remove duplicates
        }
      }, {})

    return Object.values(pagesWithComponent) as WindowsWindow[]
  }, [visitedPages, closedWindows])

  const focusAndScrollWindow = (
    dataRouteId: WindowsWindow["componentPath"],
    noScroll: boolean = false
  ) => {
    if (!dataRouteId) return
    const win = document.querySelector(`[data-route="${dataRouteId}"]`)

    if (!win) return
    simulateMouseClick(win)

    if (!noScroll) {
      const winFrame = win.querySelector(`[data-modal-frame]`)
      winFrame && winFrame.scrollTo({ top: 0 })
    }
  }

  const [ssrKey, setSsrKey] = React.useState(0)
  React.useEffect(() => {
    if (!ssrKey) {
      Promise.resolve().then(() => setSsrKey(1))
    }
  }, [])

  return (
    <React.Fragment>
      {getWindows.map(({ component: Window, context, componentPath }) => {
        return Window && closedWindows[componentPath] !== false ? (
          <Window
            key={componentPath + "-" + (ssrKey ? "ssr" : "client")}
            onClose={onRequestClose(componentPath)}
            location={pageProps.location}
            path={path}
            routeId={componentPath}
            {...context}
          />
        ) : null
      })}
    </React.Fragment>
  )
}
