import React from "react"
import {
  IScorerProps,
  MissedCount,
  ScoreCount,
  Scorer,
} from "../SmashGame.style"

export type SmashScoreBoardProps = {
  hits: number
  miss: number
} & IScorerProps

export const SmashScoreBoard: React.FC<SmashScoreBoardProps> = ({
  hits = 0,
  miss = 0,
  ...props
}) => {
  return (
    <Scorer {...props}>
      <ScoreCount>
        <b>{hits}</b>
        <span>HIT</span>
      </ScoreCount>
      <MissedCount>
        <b>{miss}</b>
        <span>MISS</span>
      </MissedCount>
    </Scorer>
  )
}
