import React from "react"
import { graphql, PageProps } from "gatsby"
import { MsawtAwtIcon } from "@react95/icons"
import {
  combineTitles,
  mapRemarkToPage,
  generatePaginationButtons,
} from "../hooks"
import {
  AnchorsGroup,
  HTMLContent,
  UiModal,
  YoutubeOrImage,
} from "../components"
import { Helmet } from "react-helmet"
import { CommonPageProps, Gallery, Markdown } from "../@types"
import { getSrc } from "gatsby-plugin-image"
import { PageWrapper } from "../styles/theme"

export const GalleryItemPageLayout: React.FC<CommonPageProps<Gallery>> =
  React.forwardRef(({ onClose, data, routeId }, ref) => {
    const itemData: Gallery = mapRemarkToPage(data.markdownRemark)
    const fullName = React.useMemo(() => {
      return combineTitles(itemData.title, itemData.subtitle, " / ")
    }, [itemData.title, itemData.subtitle])

    const paginationButtons = generatePaginationButtons(data)

    return (
      <UiModal
        title={fullName || "Photo"}
        closeModal={onClose}
        defaultPosition={{ x: 600, y: 70 }}
        icon={MsawtAwtIcon}
        mobileOrder={3}
        size="medium"
        scrollable
        ref={ref}
        data-route={routeId}
        buttons={paginationButtons}
      >
        <PageWrapper>
          <YoutubeOrImage
            ytUrl={itemData.youtubeUrl}
            image={itemData.featuredimage}
            title={itemData.title}
          />

          <h1>{itemData.title}</h1>
          <h2>{itemData.subtitle}</h2>

          {(data.markdownRemark.html || itemData?.links) && <hr />}

          {data.markdownRemark.html ? (
            <HTMLContent content={data.markdownRemark.html} />
          ) : null}

          <AnchorsGroup list={itemData?.links} style={{ marginTop: "1rem" }} />
        </PageWrapper>
      </UiModal>
    )
  })

const GalleryItemPage: React.FC<PageProps<Markdown>> = ({ data }) => {
  const itemData: Gallery = mapRemarkToPage(data.markdownRemark)
  const src = getSrc(data.markdownRemark.frontmatter.featuredimage)
  const fullName = combineTitles(itemData.title, itemData.subtitle, " / ")

  return (
    // @ts-ignore
    <Helmet>
      <title>{fullName}</title>
      <meta property="og:title" content={`Status Zero / ${fullName}`} />
      <meta property="og:image" content={src} />
      {data.markdownRemark.excerpt && (
        <meta name="description" content={data.markdownRemark.excerpt} />
      )}
      {data.markdownRemark.excerpt && (
        <meta property="og:description" content={data.markdownRemark.excerpt} />
      )}
    </Helmet>
  )
}

export default GalleryItemPage

export const pageQuery = graphql`
  query GalleryItemPageQuery(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 200)
      frontmatter {
        title
        subtitle
        date
        youtubeUrl
        links {
          text
          url
        }
        featuredimage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    prev: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
