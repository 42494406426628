import { Button, Frame } from "@react95/core"
import styled, { css } from "styled-components"
import UiLink from "../../components/UiLink"
import { BlinkAnimation, mq } from "../theme"

export const YoutubeVideoContainer = styled(Frame)`
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;

  ${mq(">md")} {
    max-height: 500px;
    min-height: 500px;
  }

  .video-yt,
  iframe {
    height: 100%;
    max-height: 500px;
    max-width: 100%;
  }
`

export const PressInfoGrid = styled.div`
  display: flex;
  gap: 1rem;
  max-height: 300px;

  ${mq("<md")} {
    max-height: unset;
    flex-direction: column;
  }
`

export const FloatingHeaderFrame = styled(Frame)`
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  flex: 1 0 40%;
  padding: 10px;
  overflow-y: auto;

  ${mq("<md")} {
    overflow-y: visible;
    flex: 1;
  }

  p {
    font-size: 1rem;
    margin: 0;
  }

  h1 {
    background-color: #656565;
    position: sticky;
    top: -9px;
    margin: -9px -4px 0;
    padding: calc(1rem + 5px) 4px;
  }

  h1 + * {
    margin-top: 0;
  }

  .rich-body *:last-child::after {
    content: ".";
    ${BlinkAnimation}
  }
`
export const PortraitContainer = styled(Frame)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`

export const PressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 1rem;
  }

  & > :last-child {
    margin-bottom: 5rem;
  }
`
