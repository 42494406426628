import React from "react"
import styled from "styled-components"
import {
  List,
  Modal as React95Modal,
  Frame as React95Frame,
  Button,
} from "@react95/core"
import { Bat, BatExec } from "@react95/icons"
import { FrameProps } from "@react95/core/@types/Frame/Frame"
import { ModalProps } from "@react95/core/@types/Modal/Modal"
import { css } from "styled-components"
import { isClient, isMobile, useMobile } from "../../hooks"
import { mq } from "../../styles/theme"

export type SizingProps = {
  width?: string
  height?: string
  maxHeight?: string
}

export enum Sizing {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const SIZING_MAP: Record<Sizing, SizingProps> = {
  [Sizing.SMALL]: {
    width: "500",
    height: "500",
  },
  [Sizing.MEDIUM]: {
    width: "500",
    maxHeight: "800",
  },
  [Sizing.LARGE]: {
    width: "700",
    height: "700",
  },
}

export type UiModalProps = {
  scrollable?: boolean
  fullscreen?: boolean
  frameProps?: FrameProps
  size?: Lowercase<keyof typeof Sizing>
  ref?: any
  icon?: React.FC<any>
  mobileOrder?: number
  padding?: number
  centered?: boolean | { offsetY?: number; offsetX?: number }
  mobileCentered?: boolean
  maxHeight?: string
  minHeight?: number | string
  defaultPosition?: { x: number | string; y: number | string }
} & Omit<ModalProps, "icon" | "defaultPosition">

type ModalTypes = ModalProps & Partial<Omit<UiModalProps, "icon">>

export const FrameOverflowWrapper = styled.div<Partial<ModalTypes>>`
  width: 100%;
  max-width: min(100%, 960px);
  margin: 0 auto;

  ${(props) =>
    props.scrollable &&
    props.padding &&
    css`
      padding: ${props.padding}px;
    `}
`

export const Modal = styled(React95Modal)<ModalTypes>`
  & > div:nth-of-type(1) {
    user-select: none;
    & > div {
      text-shadow: none;
    }
  }
  & > div:nth-of-type(2) {
    flex: 1;
    overflow: hidden;
    ${(props) =>
      props.fullscreen &&
      css`
        flex: 1;
        max-height: calc(100% - 73px);
      `}
  }

  ${({ minHeight }) =>
    minHeight &&
    css`
      /* height: unset; */
      min-height: ${typeof minHeight === "string"
        ? minHeight
        : minHeight + "px"};
    `}

  ${(props) =>
    props.fullscreen &&
    css`
      inset: 0;
      height: calc(100% - 30px);
      max-height: calc(100% - 30px);
      width: 100%;
    `}

  ${mq("<md")} {
    width: 100%;
    ${(props) => {
      if (props.mobileCentered)
        return {
          width: "90%",
          left: "calc(50% - 45%)",
          top: "20vh",
        }
      const heightOffset = props.mobileOrder * 3 + "rem"
      return props.mobileOrder && !props.fullscreen
        ? css`
            ${props.height !== "unset"
              ? css`
                  height: calc(100% - ${heightOffset});
                  bottom: 29px;
                `
              : null};
            top: ${heightOffset};
          `
        : null
    }}
  }
`

type FrameTypes = FrameProps & Partial<UiModalProps> & { maxHeight: string }

export const Frame = styled(React95Frame)<FrameTypes>`
  flex: 1;
  display: flex;
  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: scroll;
      position: relative;
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: min(72vh, ${maxHeight + "px"});
    `}

  ${mq("<md")} {
    width: 100%;
    height: 100%;
  }
`

const getPercent = (
  value: string | number,
  basedOn: "height" | "width",
  weight: number = 0
) => {
  if (typeof window === "undefined") return 0

  const fixedValue = typeof value === "string" ? value.replace("%", "") : value
  const percent = +fixedValue / 100
  if (isNaN(percent)) return 0

  if (basedOn === "height") {
    return window.innerHeight * percent - weight / 2
  }
  return window.innerWidth * percent - weight / 2
}

export const UiModal: React.FC<UiModalProps> = React.forwardRef(
  (
    {
      fullscreen,
      children,
      frameProps,
      scrollable,
      size = Sizing.MEDIUM,
      icon: Icon,
      title,
      mobileOrder = 0.5,
      padding = 16,
      centered,
      mobileCentered,
      defaultPosition,
      maxHeight,
      minHeight,
      ...restModalProps
    },
    ref
  ) => {
    const mapWidth = SIZING_MAP[size]?.width
    const mapHeight = SIZING_MAP[size]?.height
    const mapMaxHeight = SIZING_MAP[size]?.maxHeight

    const smartDefaultPosition = React.useMemo(() => {
      if (!defaultPosition?.x && !defaultPosition?.y) {
        return { x: 50, y: 50 }
      }

      const { x, y } = defaultPosition
      const xIsPercent = typeof x === "string" && x.includes("%")
      const yIsPercent = typeof y === "string" && y.includes("%")

      return {
        x: !xIsPercent ? x : getPercent(x, "width", +mapWidth),
        y: !yIsPercent ? y : getPercent(y, "height", +mapHeight),
      }
    }, [mapHeight, mapWidth, defaultPosition])

    const resultMaxHeight = React.useMemo(
      () => (fullscreen ? null : maxHeight ?? mapMaxHeight),
      [fullscreen, maxHeight, mapMaxHeight]
    )

    const ModalIcon = Icon ?? BatExec
    const modalTitle = title ?? "New Folder"

    return (
      <Modal
        fullscreen={fullscreen}
        width={mapWidth}
        height={resultMaxHeight ? "unset" : mapHeight}
        minHeight={minHeight}
        ref={ref}
        title={title ?? "New Folder"}
        icon={<ModalIcon variant="32x32_4" alt={modalTitle} />}
        mobileOrder={mobileOrder}
        mobileCentered={mobileCentered}
        defaultPosition={smartDefaultPosition}
        {...restModalProps}
        {...((isMobile || fullscreen) && {
          defaultPosition: { x: 0, y: 0 },
        })}
      >
        <Frame
          h="100%"
          boxShadow={fullscreen ? "none" : "in"}
          scrollable={scrollable}
          maxHeight={resultMaxHeight}
          padding={fullscreen || scrollable ? 0 : padding}
          mobileOrder={mobileOrder}
          data-modal-frame
          {...(frameProps as any)}
        >
          {scrollable ? (
            <FrameOverflowWrapper
              padding={padding}
              scrollable={scrollable}
              fullscreen={fullscreen}
            >
              {children}
            </FrameOverflowWrapper>
          ) : (
            <React.Fragment>{children}</React.Fragment>
          )}
        </Frame>
      </Modal>
    )
  }
)
