import { useState } from "react"
import { useEffect } from "react"
import { isClient, toggleBodyClass } from "./utils"

export const useClientSizeHelpers = () => {
  if (!isClient()) return
  useEffect(() => {
    /* Assigns the client height to a css variable. */
    document.documentElement.style.setProperty(
      "--client-height",
      document.documentElement.clientHeight + "px"
    )

    /* Assigns the client width to a css variable. */
    document.documentElement.style.setProperty(
      "--client-width",
      document.documentElement.clientWidth + "px"
    )

    // sometimes apple devices behave different than windows / android
    // this helps to create custom styles for mac/iOS
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    const isIphone = ["iPhone", "iPad"].includes(navigator.platform)
    const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)

    toggleBodyClass("is-iphone", (isMac && isIphone) || isIphone)
    toggleBodyClass("is-mac", isMac && !isIphone)
    toggleBodyClass("is-apple", isApple)

    toggleBodyClass("is-windows", /(Win)/.test(navigator.userAgent))
  }, [])
  return null
}
