import React from "react"
import { Alert, Frame } from "@react95/core"
import { navigate, PageProps } from "gatsby"
import { useQueryParam } from "../hooks"
import styled from "styled-components"
import { keyframes } from "styled-components"
import UiLink from "../components/UiLink"
import { useErrorPage } from "../queries"
import { Helmet } from "react-helmet"

type ErrorPageLayoutProps = {
  children?: React.ReactNode
  onClose?: () => void
}

const ErrorFullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a;
  color: white;
  z-index: 9999;

  a {
    color: white !important;
  }
`

const makeMeBlink = keyframes`
66% {
  opacity: 0;
}
`

const Blink = styled.span`
  animation: ${makeMeBlink} 1s steps(1) infinite;
`

const H1 = styled.h1`
  background-color: #f1f1f1;
  color: #00a;
  padding: 0 1rem;
  font-size: 1rem;
  display: inline-block;
`

const CenterText = styled.div`
  text-align: center;
`

export const ErrorPageLayout: React.FC<
  PageProps<any> & ErrorPageLayoutProps
> = ({ children, onClose, location, path }) => {
  const mounted = React.useRef(false)
  const pageData = useErrorPage()
  const [showAlert, toggleShowAlert] = React.useState(null)
  const titleParam = useQueryParam("title", location)
  const messageParam = useQueryParam("message", location)
  const isSkipParam = Boolean(useQueryParam("skip", location))

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
    if (showAlert !== null && !path?.startsWith("/error")) {
      toggleShowAlert(null)
    } else if (showAlert === null && path?.startsWith("/error")) {
      toggleShowAlert(true)
    }
  }, [location.pathname, path])

  const handleCloseAlert = () => {
    toggleShowAlert(false)
    if (isSkipParam) navigate("/")
  }

  return (
    <React.Fragment>
      {showAlert === true && (
        <Alert
          key={showAlert}
          type="error"
          title={titleParam || pageData.title}
          message={messageParam || pageData.subtitle}
          closeAlert={handleCloseAlert}
          // hasSound
          buttons={[{ value: "OK", onClick: handleCloseAlert }]}
        />
      )}
      {showAlert === false && !isSkipParam && (
        <ErrorFullScreen>
          <Frame
            w="100%"
            h="100%"
            m={0}
            p="3rem 4rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="none"
            bg="unset"
          >
            <Frame boxShadow="none" bg="unset" maxWidth="40rem">
              <CenterText>
                <H1>{pageData.blueScreen.title}</H1>
              </CenterText>

              {pageData.blueScreen.description && (
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {pageData.blueScreen.description}
                </p>
              )}

              <br />
              <br />

              <CenterText as="p">
                Please <UiLink to="/">click here to go to homepage</UiLink>
                <Blink> _</Blink>
              </CenterText>
            </Frame>
          </Frame>
        </ErrorFullScreen>
      )}

      {children}
    </React.Fragment>
  )
}

const ErrorPage: React.FC<ErrorPageLayoutProps> = () => {
  return (
    // @ts-ignore
    <Helmet>
      <title>Something went wrong</title>
    </Helmet>
  )
}

export default ErrorPage
