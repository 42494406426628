import * as React from "react"
import { ThemeProvider, List, TaskBar } from "@react95/core"
import UiLink from "../UiLink"
import styled from "styled-components"
import { ActiveClickBrightness } from "../../styles/theme/shared"
import { FoldersArray } from "../WindowsManager/windows.routes"
import { useStatusZero } from "../../queries"
import {
  Progman40,
  Progman41,
  Progman42,
  Progman43,
  Progman44,
  Progman45,
  Progman46,
  MediaAudio,
} from "@react95/icons"

const Progmans = [
  Progman40,
  Progman41,
  Progman42,
  Progman43,
  Progman44,
  Progman45,
  Progman46,
]
const ListLinkItem = styled(UiLink)`
  color: black;
  text-decoration: none;

  display: block;

  &:hover li {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active li,
  &:active {
    ${ActiveClickBrightness}
  }

  li,
  li > * {
    cursor: pointer;
  }
`

const StyledTaskbar = styled.div`
  button {
    color: white !important;
    text-shadow: 1px 1px 3px rgb(0, 0, 0);
  }
`

export const UiTaskbar = () => {
  const statusZeroData = useStatusZero()
  return (
    <StyledTaskbar>
      <TaskBar
        list={
          <List>
            {statusZeroData?.socialMedia?.length > 0 && (
              <List.Item icon={<MediaAudio variant="32x32_4" />}>
                <List>
                  {statusZeroData?.socialMedia.map((socialMedia, index) => {
                    const Icon = Progmans[index]
                    return (
                      <ListLinkItem
                        key={socialMedia.url + socialMedia.text}
                        to={socialMedia.url}
                        className="darken-on-tap"
                      >
                        <List.Item
                          icon={
                            <Icon variant="32x32_4" alt={socialMedia.text} />
                          }
                        >
                          {socialMedia.text}
                        </List.Item>
                      </ListLinkItem>
                    )
                  })}
                </List>
                Social Networking
              </List.Item>
            )}

            {FoldersArray?.map((folder) => (
              <ListLinkItem
                key={folder.url + folder.name}
                to={folder.url}
                state={folder?.state}
                className="darken-on-tap"
              >
                <List.Item icon={<folder.icon variant="32x32_4" />}>
                  {folder.name}
                </List.Item>
              </ListLinkItem>
            ))}
          </List>
        }
      />
    </StyledTaskbar>
  )
}
