import React from "react"
import styled from "styled-components"
import { css } from "styled-components"
import { mq } from "../../styles/theme"
import { ActiveClickBrightness, DottedOutline } from "../../styles/theme/shared"
import UiLink from "../UiLink"
import { DesktopFoldersProps } from "./DesktopFolders"

type CompProps = Omit<Partial<DesktopFoldersProps>, "folders">

const iconSize = {
  width: "108px",
  height: "110px",
}
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 auto;
  &,
  & > * {
    cursor: pointer;
  }

  &:focus {
    ${DottedOutline}
  }
`

// prettier-ignore
export const DesktopIconButton = styled(({ white, ...p }) => (<UiLink {...p} />))<CompProps>`
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  /* margin-bottom: 20px; */
  cursor: pointer;
  min-width: ${iconSize.width};
  max-width: ${iconSize.width};
  min-height: ${iconSize.height};
  text-decoration: none;


  ${({white}) =>
    white
      ? css`
          color: white;
          &:visited {
            color: white;
          }
        `
      : css`
          color: black;
          &:visited {
            color: black;
          }
        `}

  text-align: center;
  span {
    margin: 6px 0 0;

    word-break: break-word;
    white-space: pre-wrap;
    hyphens: auto;
  }

  &:active {
    ${IconWrapper} {
      ${ActiveClickBrightness}
    }
  }

  &:focus {
    outline: none;
    ${IconWrapper} {
      ${DottedOutline}
    }
  }
`

const DFWrapper = ({ margin, row, ...p }) => <div {...p} />
export const DesktopFoldersWrapper = styled(DFWrapper)<CompProps>`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  flex-wrap: wrap;
  flex-direction: ${({ row }) => (row ? `row` : `column`)};
  ${({ row }) =>
    row &&
    css`
      ${DesktopIconButton} {
        align-items: unset;
        justify-content: unset;
        max-width: unset;
        flex: 0 0 25%;
        ${IconWrapper} {
          max-width: ${iconSize.width};
        }

        ${mq("<md")} {
          flex: 0 0 33.33%;
        }
      }
    `};
  ${({ margin }) => margin && `margin: ${margin}`}
`
