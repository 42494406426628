import React from "react"
import {
  GALLERY_ITEM_TEMPLATE_KEY,
  MUSIC_ITEM_TEMPLATE_KEY,
} from "../../@types"
import { useFeaturedPost } from "../../queries/useFeaturedPost"
import { ArrowFillGif, CoinGif, NewPostGif, PaintGif } from "../gif"
import { UiModal, AnchorLink, UiLink, YoutubeOrImage } from "../"
import {
  ButtonArrowGif,
  FeaturedPostGrid,
  FeaturedPostWrapper,
  NewPost,
  PostEnd,
  PostStart,
  TextContent,
} from "./FeaturedPost.style"
import { Confcp118 } from "@react95/icons"
import { useCookies } from "react-cookie"

const SHOW_DELAY = 2500

export const FeaturedPost = () => {
  const data = useFeaturedPost()
  const [showModal, setShowModal] = React.useState(false)
  const [cookies, setCookie, removeCookie] = useCookies([
    "featured-post-cookie",
  ])

  const close = () => {
    setShowModal(false)
    setCookie("featured-post-cookie", data.id, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 1),
    })
  }

  const shouldHide = React.useMemo(
    () => cookies["featured-post-cookie"] === data.id,
    [cookies]
  )

  React.useEffect(() => {
    if (shouldHide) return
    setTimeout(() => setShowModal(true), SHOW_DELAY)
  }, [])

  return showModal ? (
    <UiModal
      icon={Confcp118}
      title={"OUT NOW"}
      closeModal={close}
      size="small"
      width="700"
      height={"unset"}
      maxHeight="500px"
      scrollable
      defaultPosition={{ x: "50%", y: "70%" }}
    >
      <FeaturedPostWrapper>
        <FeaturedPostGrid>
          <PostStart>
            <YoutubeOrImage
              image={data.featuredimage}
              ytUrl={data.youtubeUrl}
              title={data.title}
              useVideo={false}
            />
          </PostStart>

          <PostEnd>
            <TextContent>
              <h1>{data.subtitle}</h1>
              <h2>{data.title}</h2>
              <span>{data.date}</span>
            </TextContent>

            {data.templateKey === MUSIC_ITEM_TEMPLATE_KEY ? (
              <NewPost
                src={CoinGif}
                alt="Otter dancing with a fish"
                loading="lazy"
              />
            ) : data.templateKey === GALLERY_ITEM_TEMPLATE_KEY ? (
              <NewPost
                src={PaintGif}
                alt="Otter dancing with a fish"
                loading="lazy"
              />
            ) : (
              <NewPost
                src={NewPostGif}
                alt="Otter dancing with a fish"
                loading="lazy"
              />
            )}

            <AnchorLink
              as={UiLink}
              to={data.slug}
              onClick={close}
              style={{ marginTop: "auto" }}
            >
              Check out now{" "}
              <ButtonArrowGif
                src={ArrowFillGif}
                alt="Otter dancing with a fish"
              />
            </AnchorLink>
          </PostEnd>
        </FeaturedPostGrid>
      </FeaturedPostWrapper>
    </UiModal>
  ) : null
}
