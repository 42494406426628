import React from "react"
import { GamePanels, MissedCount, ScoreCount, Scorer } from "../SmashGame.style"
import ReactMarquee from "react-fast-marquee"
import { Marquee } from "../../../components"
import { AnchorsGroup } from "../../AnchorsGroup"
import { LinkType } from "../../../@types"
import { SmashScoreBoard } from "./SmashScoreBoard"

export type SmashGUIScreenProps = {
  title: string
  primaryButton?: LinkType
  buttons?: LinkType[]
  scorer?: {
    hits: number
    miss: number
  }
}

export const SmashGUIScreen: React.FC<SmashGUIScreenProps> = ({
  scorer,
  title = "SMASH GAME",
  primaryButton,
  buttons,
  children,
}) => {
  return (
    <GamePanels>
      {/* // TODO: create marquee component with ssr support (marquee does not show on ssr, need to show normal div for ssr and remount to marquee) */}

      <Marquee speed={70} text={title} />

      {!Object.keys(scorer || {})?.length ? null : (
        <SmashScoreBoard hits={scorer.hits} miss={scorer.miss} />
      )}

      {children}

      <AnchorsGroup list={buttons?.length ? buttons : [primaryButton]} />
    </GamePanels>
  )
}
