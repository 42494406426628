import React from "react"
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby"
import { GlobalStyle } from "@react95/core"
import { routeMatch } from "../../hooks"
import { AppGlobalStyle } from "../../styles/global"
import Layout from "../Layout"

export const WrapPageElement: React.FC<
  WrapPageElementBrowserArgs | WrapPageElementNodeArgs
> = ({ element, props }) => {
  const layoutProps = element.type?.["layoutProps"] ?? {}

  return (
    <>
      {/* @ts-ignore */}
      <GlobalStyle />
      {/* @ts-ignore */}
      <AppGlobalStyle />

      <Layout {...props} {...layoutProps}>
        {element}
      </Layout>
    </>
  )
}
