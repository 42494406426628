import React from "react"
import styled, { css } from "styled-components"
import { Button } from "@react95/core"
import UiLink from "./UiLink"
import { BaseProps, LinkType } from "../@types"

export type IAnchorLink = { fontSize?: number; onClick?: any }
export const AnchorLink = styled(Button)<IAnchorLink>`
  font-size: ${({ fontSize = 1 }) => fontSize + "rem"};
  text-decoration: none;
  text-align: center;
  display: grid;
  place-content: center;
  grid-auto-flow: column;
  cursor: pointer;

  &,
  &:active {
    padding-block: ${({ fontSize = 1 }) => fontSize * 0.75 + "rem"};
  }
`

export type IAnchorsGroupWrapper = { column?: boolean; margin?: string }
export const AnchorsGroupWrapper = styled.div<IAnchorsGroupWrapper>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: ${({ margin = "0" }) => margin};
  ${(props) =>
    props.column &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    `}
`

export const AnchorsGroup: React.FC<
  IAnchorsGroupWrapper & IAnchorLink & { list: LinkType[] } & BaseProps
> = ({ list, column, margin, fontSize, ...rest }) => {
  return (
    list?.length > 0 && (
      <AnchorsGroupWrapper column={column} margin={margin} {...(rest as any)}>
        {list.map((item, index) => {
          return (
            <AnchorLink
              as={item?.onClick ? null : UiLink}
              to={item?.url}
              key={index}
              fontSize={fontSize}
              onClick={() => item?.onClick?.()}
            >
              {item?.text}
            </AnchorLink>
          )
        })}
      </AnchorsGroupWrapper>
    )
  )
}
