import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export const IndexPageTemplate = ({ data }) => (
  <div className="home container">
    {/* <Hero /> */}
    {/* <MusicRoll /> */}
  </div>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Desktop</title>
      </Helmet>
      <IndexPageTemplate data={frontmatter} />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        description
      }
    }
  }
`
