import { spacing } from "./variables"
import { Button } from "@react95/core"
import styled, { css, keyframes } from "styled-components"

export const DottedOutline = css`
  outline: 1px dotted ${(props: any) => props.theme.colors.materialText};
`

export const ActiveClickBrightness = css`
  filter: brightness(0.9);
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${spacing(2)};
  & > * + * {
    margin-top: ${spacing(0.75)};
  }
  h1 {
    margin-block: 1rem 0;
    font-weight: lighter;
  }
  hr {
    width: 100%;
  }
`

export const Divider = styled.hr`
  width: 100%;
`

export const makeMeBlink = keyframes`
66% {
  opacity: 0;
}
`

export const BlinkAnimation = css`
  animation: ${makeMeBlink} 1s steps(1) infinite;
`
