import clsx from "clsx"
import React from "react"
import { IframeHTMLAttributes } from "react"
import { BaseProps } from "../../@types"
import { Youtube } from "../../hooks"
import UiLink from "../UiLink"
import Image from "../Image"
import styled from "styled-components"

type YoutubeProps = {
  url: string
  title: string
  width?: string
  height?: string
  useVideo?: boolean
  noAspectRatio?: boolean
} & BaseProps &
  IframeHTMLAttributes<HTMLElement>

export const YoutubeWrap = styled.section`
  display: block;
  position: relative;
  .video-img {
    position: relative;
    z-index: 1;
  }
  .video-yt {
    &__play {
      position: relative;
      z-index: 2;
      transition: all 160ms ease-in;
      transform: scale(0);
      filter: drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.125));
    }
    &__overlay {
      z-index: 2;
      inset: 0;
      display: grid;
      place-content: center;
      pointer-events: none;
    }

    &:hover {
      .video-yt__play {
        transform: scale(1);
      }
    }
  }
`
export const YoutubeVideo: React.FC<YoutubeProps> = ({
  className,
  url,
  title,
  height,
  width = "100%",
  style = { aspectRatio: "16 / 9" },
  useVideo = true,
  noAspectRatio,
  ...otherProps
}: YoutubeProps) => {
  const [iframeLoaded, setIframeLoaded] = React.useState(false)

  if (noAspectRatio) style = {}

  const { embedUrl, imageUrl, url: ytUrl } = new Youtube(url)

  return useVideo ? (
    <YoutubeWrap
      className={clsx(["video-yt", className])}
      style={{ width, height, ...style }}
    >
      {iframeLoaded ? null : (
        <Image
          className="video-img"
          src={imageUrl}
          alt={title}
          imgStyle={{
            width: "100%",
            maxHeight: height,
            height: "100%",
            position: "absolute",
            objectFit: "cover",
          }}
        />
      )}
      <iframe
        src={embedUrl}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
        style={style}
        width={width}
        height={height}
        onLoad={() => setIframeLoaded(true)}
        {...otherProps}
      />
    </YoutubeWrap>
  ) : (
    <UiLink
      to={ytUrl}
      className={clsx(["video-yt", "video--as-img", className])}
      style={{ width, height, ...style, maxHeight: height }}
    >
      <Image
        className="video-img"
        src={imageUrl}
        alt={title}
        imgStyle={{ width: "100%", maxHeight: height }}
      />
      <div className="video-yt__overlay">
        {/* <PlayIcon fill="white" className="video-yt__play" width="48" /> */}
      </div>
    </UiLink>
  )
}

type YtOrImgProps = {
  ytUrl: string
  image: string
  title: string
  useVideo?: boolean
} & BaseProps
export const YoutubeOrImage: React.FC<YtOrImgProps> = ({
  ytUrl,
  image,
  title,
  useVideo = true,
  ...rest
}) => {
  switch (true) {
    case !!ytUrl:
      return (
        <YoutubeVideo
          url={ytUrl}
          title={title}
          useVideo={useVideo}
          {...(rest as any)}
        />
      )
    case !!image:
      return <Image src={image} alt={title} {...(rest as any)} />
    default:
      return null
  }
}
