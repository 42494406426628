import React from "react"
import clsx from "clsx"
import { BaseProps } from "../@types"
import { fixUnit } from "../hooks"

type SpotifyWidgetProps = {
  url?: string
  tag?: any
  title: string
  width?: number | string | boolean
  height?: number | string | boolean
} & BaseProps

export const SpotifyWidget: React.FC<SpotifyWidgetProps> = ({
  tag: CustomTag = "div",
  url = "",
  title = "Listen now",
  className,
  width = "100%",
  height = 80,
  style,
  ...otherProps
}: SpotifyWidgetProps) => {
  if (!url) return null

  width = fixUnit(width)
  height = fixUnit(height)

  return url ? (
    <CustomTag
      {...otherProps}
      className={clsx(["spotify-widget", className])}
      style={{
        width,
        height,
        background:
          "linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(40, 40, 40, 1))",
      }}
    >
      <iframe
        frameBorder="0"
        title={title}
        allow="encrypted-media"
        style={{ width, height, pointerEvents: "all", ...style }}
        src={`${url}&theme=0`}
        loading="lazy"
      />
    </CustomTag>
  ) : null
}
