import React from "react"
import styled, { css } from "styled-components"
import ReactMarquee from "react-fast-marquee"
import { mq } from "../../styles/theme"
import { isClient } from "../../hooks"

export const MarqueeH1 = styled.h1<{ fake?: boolean }>`
  margin-block: 1rem !important;
  font-size: 3rem;
  white-space: pre;
  & + & {
    margin-inline-start: 2ch;
  }
  &:last-of-type {
    margin-inline-end: 2ch;
  }
  ${mq("<md")} {
    font-size: 1.5rem;
  }
`

export const MarqueeComp = styled(ReactMarquee)<{ fake?: boolean }>`
  margin-inline: calc(-1rem + 2px);
  width: calc(100% + calc(2rem - 4px)) !important;
  margin-block-start: -3rem;
  ${({ fake }) =>
    fake &&
    css`
      display: flex;
      text-align: center;
      justify-content: center;
      overflow: hidden;
    `}
`

export const Marquee = ({ text, duplicates = 2, speed = 70 }) => {
  const isSsr = !isClient()

  return (
    <MarqueeComp
      gradient={false}
      speed={speed}
      fake={isSsr}
      {...((isSsr && { as: "div" }) as any)}
    >
      {Array(duplicates)
        .fill(0)
        .map((_, i) => (
          <MarqueeH1 key={i} fake={isSsr}>
            {text}
          </MarqueeH1>
        ))}
    </MarqueeComp>
  )
}
