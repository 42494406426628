import React from "react"
import { Freecell1, MsawtAwtIcon, Progman19 } from "@react95/icons"
import { DesktopFolders } from "../components/DesktopFolders/DesktopFolders"
import { UiModal } from "../components"
import { useGalleryPage } from "../queries"
import { Helmet } from "react-helmet"
import { combineTitles } from "../hooks"
import { CommonPageProps } from "../@types"

export const GalleryPageLayout: React.FC<CommonPageProps> = React.forwardRef(
  ({ children, onClose, routeId }, ref) => {
    const pageData = useGalleryPage()

    return (
      <React.Fragment>
        <UiModal
          title="Gallery"
          closeModal={onClose}
          defaultPosition={{ x: 200, y: 70 }}
          icon={Freecell1}
          mobileOrder={2}
          size="medium"
          scrollable
          ref={ref}
          data-route={routeId}
        >
          <h1>Gallery</h1>
          <hr />

          <DesktopFolders
            row
            margin="1.5em -1rem"
            folders={
              pageData.map((item) => ({
                icon: item.youtubeUrl ? Progman19 : MsawtAwtIcon,
                imageIcon: item.featuredimage,
                name: combineTitles(item.title, item.subtitle, " / "),
                url: item.slug,
              })) || []
            }
          />
        </UiModal>

        {children}
      </React.Fragment>
    )
  }
)

const GalleryPage: React.FC = () => {
  return (
    // @ts-ignore
    <Helmet>
      <title>Gallery</title>
      <meta property="og:title" content={`Gallery / Status Zero`} />
    </Helmet>
  )
}

export default GalleryPage
