import React from "react"
import { isUrl } from "../hooks"
import { GatsbyLinkProps, Link } from "gatsby"

type UiLinkProps = {
  to: string
  disabled?: boolean
  className?: string
} & Partial<GatsbyLinkProps<any>>

const UiLink: React.FC<UiLinkProps> = ({
  to,
  children,
  className,
  disabled,
  ...otherProps
}) => {
  const notEmpty = (v: string) => !!v && v !== "#"
  const TextLink = <b className={className}>{children}</b>

  switch (true) {
    case disabled:
      return TextLink

    case isUrl(to):
      return (
        <a
          className={className}
          href={to}
          target="_blank"
          rel="noopener"
          {...(otherProps as any)}
        >
          {children}
        </a>
      )

    case !isUrl(to) && notEmpty(to):
      return (
        <Link className={className} to={to} {...(otherProps as any)}>
          {children}
        </Link>
      )

    default:
      return TextLink
  }
}

export default UiLink
