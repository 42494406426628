exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-error-page-tsx": () => import("./../../../src/templates/error-page.tsx" /* webpackChunkName: "component---src-templates-error-page-tsx" */),
  "component---src-templates-gallery-item-tsx": () => import("./../../../src/templates/gallery-item.tsx" /* webpackChunkName: "component---src-templates-gallery-item-tsx" */),
  "component---src-templates-gallery-page-tsx": () => import("./../../../src/templates/gallery-page.tsx" /* webpackChunkName: "component---src-templates-gallery-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-music-item-tsx": () => import("./../../../src/templates/music-item.tsx" /* webpackChunkName: "component---src-templates-music-item-tsx" */),
  "component---src-templates-music-page-tsx": () => import("./../../../src/templates/music-page.tsx" /* webpackChunkName: "component---src-templates-music-page-tsx" */),
  "component---src-templates-press-page-tsx": () => import("./../../../src/templates/press-page.tsx" /* webpackChunkName: "component---src-templates-press-page-tsx" */),
  "component---src-templates-smashgame-page-tsx": () => import("./../../../src/templates/smashgame-page.tsx" /* webpackChunkName: "component---src-templates-smashgame-page-tsx" */)
}

