import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { mapRemarkToPage } from "../hooks"
import { Music } from "../@types"

export const useMusicPage = () => {
  const data = useStaticQuery(
    graphql`
      query MusicPageQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "music-item" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                subtitle
                date(formatString: "DD MMM YY")
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 56
                      aspectRatio: 1
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return mapRemarkToPage<Music[]>(data.allMarkdownRemark.edges)
}
