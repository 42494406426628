import styled, { css } from "styled-components"
import { spacing } from "../theme/variables"

import {
  Fieldset as React95Fieldset,
  Input as React95Input,
  TextArea as React95TextArea,
  ProgressBar as React95ProgressBar,
} from "@react95/core"
import { mq } from "../theme"

type FieldsetProps = {
  flex?: boolean
  center?: boolean
}
export const Fieldset = styled(React95Fieldset)<FieldsetProps>`
  ${({ center }) =>
    center &&
    css`
      legend {
        margin: auto;
      }
    `}
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      gap: 1rem;
      padding-top: ${spacing(0.5)};
      ${mq("<md")} {
        flex-direction: column;
      }
    `}
`
export const Input = styled(React95Input)`
  color: black;
  flex: 1;
  transition: opacity 0.2s ease-in-out;
  &[disabled] {
    opacity: 0.5;
  }
`
export const TextArea = styled(React95TextArea)`
  width: 100%;
  color: black;
  flex: 1;
  transition: opacity 0.2s ease-in-out;
  &[disabled] {
    opacity: 0.5;
  }
`
export const ProgressBar = styled(React95ProgressBar)`
  flex: 1;
  & > div:first-of-type {
    color: black;
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${spacing(2)};
  & > * + * {
    margin-top: ${spacing(0.75)};
  }
  h1 {
    margin-block: 1rem 0;
    font-weight: lighter;
  }
`
export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  &,
  & > * + * {
    margin-top: ${spacing(0.75)};
  }
`
