import React from "react"
import { navigate } from "gatsby"

export const useQueryParam = (name: string, location?: Location) => {
  return React.useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get(name) || ""
  }, [name, location])
}

export const navigateWithParams = (url: string, params: Record<string, any>) =>
  navigate(url + "?" + new URLSearchParams(params).toString())
