import { Frame } from "@react95/core"
import styled, { keyframes } from "styled-components"
import { css } from "styled-components"
import { mq } from "../../styles/theme"
import * as Marquee from "../../components/Marquee/Marquee"
import { AnchorLink } from "../AnchorsGroup"
import { GAME_CONFIG } from "./smash-game.config"
import type { SmashGameElement } from "./SmashGame"

export const flyingMob = keyframes`
    to {
        transform: translateY(${-GAME_CONFIG.mob.width}px);
    }
`
export const rotation = (isOdd?: boolean) => keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(${isOdd ? "-1turn" : "1turn"});
    }
`
export const rotationHaha = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(3turn);
    }
`

export const GameLayout = styled.div`
  position: absolute;
  inset: 0;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
`

export const CloseGameButton = styled(AnchorLink)`
  height: 50px;
  aspect-ratio: 1/1;
`
export const GamePanels = styled(Frame)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: min(330px, 100%);
  padding: 1rem;
  ${Marquee.MarqueeComp} {
    margin-top: 0;
    line-height: 0.5;
  }
  & > * {
    width: 100%;
  }
`

export const Rules = styled.div`
  margin-top: 1rem;
  font-size: 0.8rem;
  text-align: center;

  ul {
    padding: 0;
    margin-bottom: 2rem;

    li {
      font-size: 1rem;
      list-style: none;
    }

    & > li + li {
      border-top: 5px dotted currentColor;
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }
`

export const ScoreCount = styled.span``

export const MissedCount = styled.span`
  font-size: 2rem;
  color: red;
`

export type IScorerProps = {
  margin?: string
  small?: boolean
  absolute?: boolean
}
export const Scorer = styled.div<IScorerProps>`
  min-width: 200px;
  min-height: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  border: ${({ theme }) => theme.colors.materialText} 2px solid;
  box-shadow: 1px 1px 5px ${({ theme }) => theme.colors.materialText};
  text-shadow: 1px 1px 5px currentColor;
  text-align: center;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    `};

  b {
    font-size: 2rem;
  }
  span {
    font-size: 1rem;
  }
  b + span {
    margin-inline-start: 1ch;
  }

  ${ScoreCount},
  ${MissedCount} {
    flex: 1;
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  ${MissedCount} {
    margin-inline-start: 1rem;
  }
`

export const GameGUI = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  height: fit-content;
  & > * {
    user-select: none;
  }

  ${AnchorLink} {
    margin-inline-start: auto;
  }
`

export const getWindowHeight = () => {
  if (typeof window !== "undefined") {
    return window.innerHeight
  }
  return 0
}

type IMob = Omit<SmashGameElement, "id"> & {
  odd?: boolean
  didMissedOnce?: boolean
}

export const Mob = styled.button<IMob>`
  position: absolute;
  width: ${GAME_CONFIG.mob.width}px;
  height: ${GAME_CONFIG.mob.width}px;
  display: grid;
  place-content: center;
  top: 0;
  font-size: 2rem;
  background-color: transparent;
  border: none;
  left: ${({ left }) => left}px;
  transform: translateY(${getWindowHeight()}px);
  animation: ${flyingMob} 3s linear;
  cursor: pointer;
  user-select: none;
  & > * {
    cursor: pointer;
  }

  ${mq("<md")} {
    width: ${GAME_CONFIG.mob.width * GAME_CONFIG.mob.mobileSizeMultiplier}px;
    height: ${GAME_CONFIG.mob.height * GAME_CONFIG.mob.mobileSizeMultiplier}px;
  }

  svg {
    transition: all 0.1s ease-in-out;
    animation: ${({ odd: isOdd }) => rotation(isOdd)} 10s linear infinite;
    ${({ didMissedOnce }) => didMissedOnce && `filter: blur(5px);`}
  }
`
