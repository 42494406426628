import React from 'react'
import { WrapPageElement } from './src/components'
import { GlobalStoreProvider } from './src/store'
import { ThemeProvider } from '@react95/core'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme="matrix">
      <GlobalStoreProvider>{element}</GlobalStoreProvider>
    </ThemeProvider>
  )
}

export const wrapPageElement = (props) => {
  return <WrapPageElement {...props} />
}

export const onInitialClientRender = () => {
  window.___APP_INITIAL_RENDER_COMPLETE = true
}
