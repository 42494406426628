import styled, { createGlobalStyle } from "styled-components"
import { mq } from "./theme"
import { Variables } from "./theme/variables"

export const AppLayout = styled.div`
  display: flex;
  min-height: calc(100vh - 28px);
  max-height: calc(100vh - 28px);
  ${mq("<md")} {
    .is-apple & {
      min-height: calc(100vh - 109px);
      max-height: calc(100vh - 109px);
    }
  }
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  position: relative;
  a,
  a:visited {
    color: ${(props: any) => props.theme.colors.materialText};
  }
`

export const AppGlobalStyle = createGlobalStyle`
  html {
    height: calc(100% - 28px);
    overflow: hidden;
  }
  
  body {
    height: 100%;
    background-color: ${Variables.$bg}!important;
    color: #35FF69;
    text-shadow: 0px 0px 2px currentColor;
   
  }

  ::selection {
      background-color: var(--text-selection-color);
      text-shadow: 0px 0px 2px black;
  }

  .container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 3rem;
  }

  
  .noise-image {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      opacity: 0.7;
      pointer-events: none;
      mix-blend-mode: overlay;
      background-image: url("/img/noise.gif");
      background-attachment: fixed;
    }
  }

`
