import React from "react"
import { graphql, PageProps } from "gatsby"
import { Frame } from "@react95/core"
import { Progman24 } from "@react95/icons"
import {
  AnchorsGroup,
  HTMLBody,
  HTMLContent,
  Image,
  UiModal,
  Marquee,
} from "../components"
import { mapRemarkToPage } from "../hooks"
import { useStatusZero } from "../queries"
import { PageWrapper } from "../styles/theme"

import { FloatingHeaderFrame } from "../styles/views/press.style"
import { ImageContainer } from "../styles/views/about.style"
import { XFilesGif } from "../components/gif"
import { Helmet } from "react-helmet"
import { CommonPageProps, Markdown } from "../@types"

export const AboutPageLayout: React.FC<CommonPageProps> = React.forwardRef(
  ({ data, onClose, path, routeId }, ref) => {
    const statusZeroData = useStatusZero()
    const pageData = mapRemarkToPage<{
      title: string
      description: string
      embedHtml: string
      submitText: string
      afterSubmitText: string
      featuredimage: any
    }>(data.markdownRemark)

    return (
      <UiModal
        title="About"
        closeModal={onClose}
        defaultPosition={{ x: "70%", y: 50 }}
        mobileOrder={2}
        icon={Progman24}
        size="large"
        height="unset"
        maxHeight="700"
        scrollable
        ref={ref}
        data-route={routeId}
      >
        <PageWrapper>
          <ImageContainer>
            <Image src={pageData.featuredimage} alt={pageData.title} />
            <div className="x-files noise-image">
              <img src={XFilesGif} loading="lazy" />
            </div>
          </ImageContainer>

          <Marquee text={statusZeroData.title} speed={20} duplicates={3} />

          <FloatingHeaderFrame padding="10" bg="#656565">
            {data.markdownRemark?.html && (
              <HTMLContent content={data.markdownRemark?.html} />
            )}
          </FloatingHeaderFrame>

          <Frame bg="#656565">
            {pageData.embedHtml && <HTMLBody content={pageData.embedHtml} />}
          </Frame>

          <AnchorsGroup list={statusZeroData?.socialMedia} margin="1rem 0" />
        </PageWrapper>
      </UiModal>
    )
  }
)

const AboutPage: React.FC<PageProps<Markdown<any>>> = ({ data }) => {
  return (
    // @ts-ignore
    <Helmet>
      <title>About</title>
      <meta
        property="og:title"
        content={`${data.markdownRemark.frontmatter.title} / Status Zero`}
      />
      {data.markdownRemark.excerpt && (
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.excerpt}
        />
      )}
      {data.markdownRemark.excerpt && (
        <meta property="og:description" content={data.markdownRemark.excerpt} />
      )}
    </Helmet>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        description
        embedHtml
        featuredimage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
