import urlParser from "js-video-url-parser"

export class Youtube {
  constructor(private link: string) {}

  get parsed() {
    if (!this.link) return null
    return urlParser.parse(this.link)
  }

  get url() {
    return this.parsed?.id
      ? `https://www.youtube.com/watch?v=${this.parsed?.id}`
      : ""
  }
  get imageUrl() {
    return this.parsed?.id
      ? `https://img.youtube.com/vi/${this.parsed?.id}/maxresdefault.jpg`
      : ""
  }
  get embedUrl() {
    return this.parsed?.id
      ? `https://www.youtube.com/embed/${this.parsed?.id}?modestbranding=1&rel=0&iv_load_policy=3`
      : ""
  }
}
