import styled, { css } from "styled-components"
import { spacing } from "../theme/variables"
import ReactMarquee from "react-fast-marquee"

import { mq } from "../theme"

export const AboutWrapper = styled.div``

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 1.2 / 1;
  width: 100%;
  flex: 1;
  overflow: hidden;

  .ui-image {
    width: 100%;
    max-height: calc(100% - 4rem);
    max-width: calc(100% - 8rem);
    aspect-ratio: 1/1;
    z-index: 1;
  }

  .x-files {
    position: absolute;
    inset: 0;
    z-index: 0;
    img {
      width: 100%;
    }
  }
`
