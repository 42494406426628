import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LinkType, Social } from "../@types"

export const useStatusZero = (isPreviewMode?: boolean) => {
  if (isPreviewMode) return null
  const data = useStaticQuery(
    graphql`
      query StatusZeroQuery {
        markdownRemark(frontmatter: { settingsKey: { eq: "status-zero" } }) {
          html
          excerpt(pruneLength: 200)
          frontmatter {
            title
            keywords
            contactEmail
            socialMedia {
              text
              url
            }
            ogImage {
              childImageSharp {
                gatsbyImageData(width: 512, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  )

  const { markdownRemark } = data
  return { ...markdownRemark, ...markdownRemark?.frontmatter } as {
    html: string
    excerpt: string
    title: string
    keywords: string[]
    contactEmail: string
    socialMedia: LinkType[]
    ogImage: any
  }
}
