import { useState } from "react"
import { useEffect } from "react"
import { isClient } from "./utils"

const colors = [
  "#990202",
  "#07b53b",
  "#2d64ed",
  "#f99f6a",
  "#f57af5",
  "#067272",
]
// Picks a random text selection color on every user click.
export const getRandomColor = () => {
  const randomColor = colors[Math.floor(Math.random() * colors.length)]
  return randomColor
}

// Sets a random color on the body element as the current text selection color.
const setRandomColor = () =>
  document.body.style.setProperty("--text-selection-color", getRandomColor())

export const useColorfulSelection = () => {
  const [color, setColor] = useState(getRandomColor())

  useEffect(() => {
    if (!isClient()) return
    document.body.addEventListener("click", setRandomColor, false)
    setRandomColor()
  }, [])

  return [color, setColor]
}
