import React from "react"
import SEO from "./Layouts/SEO"
import { WrapPageElementBrowserArgs } from "gatsby"
import { UiTaskbar } from "./Layouts/UiTaskbar"
import { AppLayout } from "../styles/global"
import { WindowsManager } from "./WindowsManager/WindowsManager"
import { Desktop } from "./Desktop"
import { useClientSizeHelpers, useColorfulSelection } from "../hooks"
import { FeaturedPost } from "./FeaturedPost/FeaturedPost"

const Layout: React.FC<WrapPageElementBrowserArgs["props"]> = ({
  children,
  ...pageProps
}) => {
  useColorfulSelection()
  useClientSizeHelpers()

  // remove trailing slash
  const path = pageProps.location?.pathname || pageProps.path
  return (
    <>
      <SEO />

      <AppLayout>
        <Desktop />
        <FeaturedPost />
        <WindowsManager {...pageProps} path={path.replace(/\/$/, "")} />
        {children}
      </AppLayout>

      <UiTaskbar />
    </>
  )
}

export default Layout
